// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/trash_ico_black.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.message[data-v-3910d01f] {\n  display: block;\n  width: 100%;\n  padding: 0 15px 20px;\n}\n.message__name[data-v-3910d01f] {\n  font-weight: bold;\n}\n.message__avatar[data-v-3910d01f] {\n  width: 25px;\n  min-width: 25px;\n  height: 25px;\n  margin-right: 10px;\n}\n.message__author-info-wrapper[data-v-3910d01f] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 5px;\n}\n.message__textarea[data-v-3910d01f] {\n  height: 100px;\n  margin-bottom: 5px;\n}\n.message__btns-wrapper[data-v-3910d01f] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  margin-left: auto;\n}\n.message__btn[data-v-3910d01f] {\n  margin-left: 5px;\n  font-weight: bold;\n}\n.message__save-btn[data-v-3910d01f] {\n  width: auto;\n  margin-left: auto;\n  padding: 0 10px;\n}\n.message__content-wrapper[data-v-3910d01f] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-top: 5px;\n  font-size: 12px;\n}\n.message__date[data-v-3910d01f] {\n  font-size: 10px;\n  opacity: 0.5;\n}\n.message__text--deleted[data-v-3910d01f] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  opacity: 0.75;\n}\n.message__text--deleted[data-v-3910d01f]::before {\n  content: '';\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 50%;\n  background-repeat: no-repeat;\n  opacity: 0.75;\n}\n", ""]);
// Exports
module.exports = exports;
