(<template>
  <article class="message">
    <div class="message__author-info-wrapper">
      <tt-avatar :avatar="message.avatar"
                 class="message__avatar" />
      <p class="message__name">{{ message.author }}</p>
    </div>
    <div v-if="isEditState && !isDeletedMessage"
         class="message__edit-wrapper">
      <sk-textarea :no-description="true"
                   :preselected-value="messageText"
                   :placeholder="messagePlaceholder"
                   class="message__textarea"
                   @fieldvaluechanged="setLocalMessage" />
      <button class="sk-btn sk-btn--default message__save-btn"
              @click="editMessage">{{ $pgettext('translate', 'Save') }}</button>
    </div>
    <template v-else>
      <p v-if="isDeletedMessage"
         class="message__text message__text--deleted">{{ deletedMessageText }}</p>
      <p v-else
         class="message__text">{{ messageText }}</p>
      <div class="message__content-wrapper">
        <p class="message__date">{{ messageDate }}</p>
        <div v-if="showActionBtns"
             class="message__btns-wrapper">
          <button class="sk-link sk-link--default message__btn"
                  @click="setEditState(true)">{{ $gettext('Edit') }}</button>
          <button class="sk-link sk-link--default message__btn"
                  @click="openDeleteModal">{{ $gettext('Delete') }}</button>
        </div>
      </div>
    </template>
  </article>
</template>)

<script>
  export default {
    props: {
      message: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        isEditState: false,
        localMessage: ''
      };
    },
    computed: {
      messageId() { return this.message.id; },
      messageDate() { return this.$moment(this.message.date).format('DD MMM HH:mm'); },
      messageText() { return this.message.data?.text; },
      deletedMessageText() {
        const template = this.$gettext('%{name} deleted a message');
        return this.$gettextInterpolate(template, {name: this.message.author});
      },
      messagePlaceholder() { return this.$gettext('Write here...'); },
      isDeletedMessage() { return !this.messageText; },
      isOwnMessage() { return this.message.own; },
      showActionBtns() {
        const messageTimeMoment = this.$moment(this.message.date);
        return this.$moment().diff(messageTimeMoment, 'minutes') < 5 && !this.isDeletedMessage && this.isOwnMessage;
      }
    },
    methods: {
      setLocalMessage(value) {
        this.localMessage = value;
      },
      setEditState(value) {
        this.isEditState = value;
      },
      editMessage() {
        this.setEditState(false);
        if (!this.localMessage.trim()) {
          this.openDeleteModal();
          return;
        }
        this.$emit('editmessage', this.messageId, this.localMessage);
      },
      deleteMessage() {
        this.$emit('deletemessage', this.messageId);
      },
      openDeleteModal() {
        this.$store.commit('InfoModalStore/setInfoModal', {
          text: this.$gettext('Are you sure you wish to delete the message?'),
          method: 'deleteMessage',
          context: this
        });
      }
    },
    mounted() {
      this.setLocalMessage(this.messageText);
    }
  };
</script>

<style scoped>
  .message {
    display: block;
    width: 100%;
    padding: 0 15px 20px;
  }

  .message__name {
    font-weight: bold;
  }

  .message__avatar {
    width: 25px;
    min-width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .message__author-info-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .message__textarea {
    height: 100px;
    margin-bottom: 5px;
  }

  .message__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }

  .message__btn {
    margin-left: 5px;
    font-weight: bold;
  }

  .message__save-btn {
    width: auto;
    margin-left: auto;
    padding: 0 10px;
  }

  .message__content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 12px;
  }

  .message__date {
    font-size: 10px;
    opacity: 0.5;
  }

  .message__text--deleted {
    position: relative;
    display: flex;
    align-items: center;
    opacity: 0.75;
  }

  .message__text--deleted::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico_black.svg);
    background-position: 50% 50%;
    background-size: 50%;
    background-repeat: no-repeat;
    opacity: 0.75;
  }
</style>
