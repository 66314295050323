(<template>
  <article :class="{'in-progress': progressActive}"
           class="admin-chat is-progress-bar">
    <new-message @sendmessage="sendMessage" />
    <div v-if="messages.length"
         class="admin-chat__messages">
      <message v-for="message in messages"
               :key="message.date"
               :message="message"
               @editmessage="editMessage"
               @deletemessage="deleteMessage" />
    </div>
  </article>
</template>)

<script>
  import {mapActions, mapGetters} from 'vuex';
  import WSManager from '@/modules/ws_data_module';
  import NewMessage from '@/components/admin_chat/NewMessage';
  import Message from '@/components/admin_chat/Message';

  export default {
    asyncData({store, route, fetcher}) {
      let promisesArr = '';
      const {id} = route.params;
      const {entityType = 'job', token} = route.query;
      const params = {
        entityId: id,
        entityType
      };

      if (token && fetcher) {
        fetcher.setToken(token);
        store.commit('TTAuthStore/setGodMode', true);
      }

      if (!store.getters['AdminChatStore/messages']?.length && (store.state.TTAuthStore.godmode || token)) {
        promisesArr = [...promisesArr, store.dispatch('AdminChatStore/fetchAdminMessages', params)];
      }
      if (!store.state.AdminChatStore.adminInfo && store.state.TTAuthStore.godmode && store.getters['UserInfoStore/adminUid']) {
        promisesArr = [...promisesArr, store.dispatch('AdminChatStore/getAdminInfo', [store.getters['UserInfoStore/adminUid']])];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'new-message': NewMessage,
      message: Message
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('AdminChatStore', ['messages']),
      jobId() { return this.$route.params.id || ''; },
      entityType() { return this.$route.query.entityType || 'job'; },
      isOnTranslation() { return this.entityType === 'order'; }
    },
    watch: {
      $route(newValue) {
        // reset godmode on route change other than token system-log page access
        if (newValue.name !== 'SystemLog') this.resetGodmode();
      }
    },
    methods: {
      ...mapActions('AdminChatStore', [
        'sendAdminMessage',
        'editAdminMessage',
        'removeAdminMessage'
      ]),
      sendMessage(msg) {
        this.progressActive = true;
        const payload = {
          entityId: this.jobId,
          entityType: this.entityType,
          message: {text: msg}
        };

        this.sendAdminMessage(payload).then(() => {
          this.progressActive = false;
        });
      },
      editMessage(msgId, msg) {
        this.progressActive = true;
        this.editAdminMessage({msgId, message: {text: msg}}).then(() => {
          this.progressActive = false;
        });
      },
      deleteMessage(msgId) {
        this.progressActive = true;
        this.removeAdminMessage({msgId}).then(() => {
          this.progressActive = false;
        });
      },
      subscribeToAdminDiscussionChannel(route, vm) {
        if (!this.isOnTranslation && route.meta && route.meta.adminChat && route.params.id && vm.$webSocket) {
          vm.$root.$jobAdminDiscussionChannel = vm.$webSocket.subscribe((data) => {
            // data contains the data sent by the backend
            const message = data || [];
            WSManager.setDataFromAdminDiscussionChannel(vm, message);
          }, 'JobAdminDiscussionChannel', route.params.id);
        } else if (this.isOnTranslation && route.meta && route.meta.adminChat && route.params.id && vm.$webSocket) {
          vm.$root.$orderAdminDiscussionChannel = vm.$webSocket.subscribe((data) => {
            // data contains the data sent by the backend
            const message = data || [];
            WSManager.setDataFromAdminDiscussionChannel(vm, message);
          }, 'OrderAdminDiscussionChannel', route.params.id);
        }
      },
      unsubscribeFromAdminDiscussionChannel(route, vm) {
        if (!this.isOnTranslation && route.meta && route.meta.adminChat && route.params.id && vm.$root.$jobAdminDiscussionChannel && vm.$webSocket) {
          vm.$root.$jobAdminDiscussionChannel.unsubscribe();
        } else if (this.isOnTranslation && route.meta && route.meta.adminChat && route.params.id && vm.$root.$orderAdminDiscussionChannel && vm.$webSocket) {
          vm.$root.$orderAdminDiscussionChannel.unsubscribe();
        }
      },
      resetGodmode() {
        // if logged out system log access reset godmode state
        if (!this.$store.state.TTAuthStore.loggedIn) {
          this.$store.commit('TTAuthStore/setGodMode', false);
          localStorage.removeItem('authToken');
        }
      }
    },
    mounted() {
      if (this.$route.query.token) localStorage.setItem('authToken', this.$route.query.token);
    },
    beforeDestroy() {
      this.resetGodmode();
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.subscribeToAdminDiscussionChannel(to, vm);
      });
    },
    beforeRouteLeave(to, from, next) {
      this.resetGodmode();
      this.$store.commit('AdminChatStore/clearStore');
      next((vm) => {
        vm.unsubscribeFromAdminDiscussionChannel(to, vm);
      });
    }
  };
</script>

<style scoped>
  .admin-chat {
    padding: 20px 15px;
  }
</style>
